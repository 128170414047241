export const apiIndex = async (endpoint, params = {}) => {
	const { index } = useApi()

	return await index(endpoint, params)
}

export const apiShow = async (endpoint, id, params = {}) => {
	const { show } = useApi()

	return await show(endpoint, id, params)
}

export const apiCreateOrUpdate = async (endpoint, data, id = null) => {
	const { createOrUpdate } = useApi()

	return await createOrUpdate(endpoint, data, id)
}

export const apiDestroy = async (endpoint, id, data = null) => {
	const { destroy } = useApi()

	return await destroy(endpoint, id, data)
}

export const apiRequest = async (endpoint, method = 'get', body = null) => {
	const data = ref(null)
	const links = ref(null)
	const meta = ref(null)

	const client = useSanctumClient()

	const {
		data: response,
		status,
		error,
		refresh,
	} = await useAsyncData(endpoint, () =>
		client(endpoint, {
			method,
			body,
		})
	)

	const responseValue = toValue(response)

	data.value = responseValue?.data ?? responseValue
	links.value = responseValue?.links
	meta.value = responseValue?.meta

	return {
		data,
		links,
		meta,
		status,
		error,
		refresh,
	}
}
