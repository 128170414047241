export default {
  blank: () => import("/home/igor/www/admin.qcontrol/layouts/blank.vue"),
  "components-default-layout-with-horizontal-nav": () => import("/home/igor/www/admin.qcontrol/layouts/components/DefaultLayoutWithHorizontalNav.vue"),
  "components-default-layout-with-vertical-nav": () => import("/home/igor/www/admin.qcontrol/layouts/components/DefaultLayoutWithVerticalNav.vue"),
  "components-footer": () => import("/home/igor/www/admin.qcontrol/layouts/components/Footer.vue"),
  "components-nav-bar-notifications": () => import("/home/igor/www/admin.qcontrol/layouts/components/NavBarNotifications.vue"),
  "components-nav-search-bar": () => import("/home/igor/www/admin.qcontrol/layouts/components/NavSearchBar.vue"),
  "components-navbar-shortcuts": () => import("/home/igor/www/admin.qcontrol/layouts/components/NavbarShortcuts.vue"),
  "components-navbar-theme-switcher": () => import("/home/igor/www/admin.qcontrol/layouts/components/NavbarThemeSwitcher.vue"),
  "components-user-profile": () => import("/home/igor/www/admin.qcontrol/layouts/components/UserProfile.vue"),
  default: () => import("/home/igor/www/admin.qcontrol/layouts/default.vue"),
  "notifications-checklist": () => import("/home/igor/www/admin.qcontrol/layouts/notifications/Checklist.vue"),
  "notifications-prescription": () => import("/home/igor/www/admin.qcontrol/layouts/notifications/Prescription.vue"),
  "notifications-report": () => import("/home/igor/www/admin.qcontrol/layouts/notifications/Report.vue")
}