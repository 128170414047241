{
	"app": {
		"empty_data": "Данных нет",
		"currency": "сом",
		"data_not_found": "Ничего не найдено"
	},
	"theme": {
		"system": "Системная",
		"light": "Сетлая",
		"dark": "Темная"
	},
	"errors": {
		"403": "У вас недостаточно прав",
		"404": "Не найдено"
	},
	"table": {
		"actions": "Действия",
		"no_data_text": "Ничего не найдено",
		"search_placeholder": "Поиск",
		"id": "Id",
		"confirm_delete": "Удалить строку ?"
	},
	"pagination": {
		"previous": "Предыдущий",
		"next": "Следующий",
		"all": "Все"
	},
	"editor": {
		"table": "Таблица",
		"text_formatting": "Форматирование текста",
		"add_column_before": "Добавить колонку до",
		"add_column_after": "Добавить колонку после",
		"column_delete": "Удалить колонку",
		"add_row_before": "Добавить строку до",
		"add_row_after": "Добавить строку после",
		"row_delete": "Удалить строку",
		"merge_cells": "Обьеденить ячейки",
		"split_cell": "Разделить ячейки",
		"insert_table": "Добавить таблицу",
		"bold": "Жирный",
		"italic": "Курсив",
		"strike": "Перечеркнутый текст",
		"paragraph": "Параграф",
		"h1": "Заголовок 1",
		"h2": "Заголовок 2",
		"h3": "Заголовок 3",
		"bullet_list": "Список",
		"ordered_list": "Нумерованный список",
		"clean": "Очистить форматирование",
		"align_left": "Выровнять слева",
		"align_right": "Выровнять справа",
		"align_center": "Выровнять по центру",
		"toggle_header_cell": "Toggle header"
	},
	"menu": {
		"home": "Главная",
		"projects": "Проекты",
		"employees": "Сотрудники",
		"objects": "Объекты",
		"profile": "Профиль",
		"wiki": "Wiki",
		"settings": "Настройки"
	},
	"form": {
		"save": "Сохранить",
		"cancel": "Отмена",
		"select_item": "Выберите значение",
		"file_input": "Выберите файл",
		"delete": "Удалить",
		"files": "Вложения",
		"alert_submit": "Форма отправлена",
		"data_added": "Данные добавлены",
		"data_updated": "Данные обновлены",
		"error": "Форма содержит ошибки",
		"deleted": "Данные удалены",
		"internal_error": "Произошла ошибка"
	},
	"snackbar": {
		"success": "Действие успешно",
		"error": "Произошла ошибка"
	},
	"dialog": {
		"text": "Подтверждаете действие ?",
		"confirm_button_text": "Да",
		"cancel_button_text": "Нет",
		"add_button": "Добавить",
		"edit_button": "Редактировать",
		"add_title": "Добавить",
		"edit_title": "Редактировать",
		"save": "Сохранить",
		"cancel": "Отмена"
	},
	"login": {
		"title": "Добро пожаловать в Q.Control👋",
		"subtitle": "Авторизуйтесь для начала работ (можно войти с помощью почты или номера телефона)",
		"password": "Пароль",
		"forgot_password": "Забыли пароль ?",
		"remember_me": "Запомнить меня",
		"submit": "Войти",
		"login": "Номер телефона",
		"login_placeholder": "+996705554433"
	},
	"forgot_password": {
		"title": "Забыли пароль? 🔒",
		"subtitle": "Введите свой номер телефона или email и мы отправим вам код",
		"send": "Отправить",
		"back_login": " Вернуться к авторизации",
		"login": "Номер телефона или email",
		"login_placeholder": "",
		"code_sent": "Код отправлен"
	},
	"reset_password": {
		"title": "Сброс пароля 🔒",
		"new_password": "Новый пароль",
		"confirm_password": "Подтвердить новый праоль",
		"update_password": "Обновить пароль",
		"back_login": " Вернуться к авторизации"
	},
	"two_steps": {
		"title": "Подтверждение кода 💬",
		"sent_to_phone": "Мы отправили код на ваш номер телефона",
		"sent_to_email": "Мы отправили код на ваш email",
		"type_code": "Введите код",
		"submit": "Отправить",
		"didnot_get_code": "Не получили код ?",
		"resend": "Отправить повторно код",
		"login_save_error": "Что-то пошло не так"
	},
	"project": {
		"project": "Проект",
		"projects": "Проекты",
		"title": "Название",
		"count": "Количество",
		"objects_count": "Объектов",
		"object_employee": "Ответственные",
		"progress": "Прогресс",
		"date_start": "Начало",
		"date_end": "Окончание",
		"date_created": "Создан",
		"date_start_full": "Дата начала",
		"date_end_full": "Дата окончания",
		"date_created_full": "Дата создания",
		"search_placeholder": "Поиск проекта",
		"add_button": "Добавить",
		"add_dialog_title": "Добавить проект",
		"status": "Статус",
		"priority": "Приоритет",
		"supervision": "Технадзор",
		"project_not_found": "Проект не найден",
		"branch": "Филиал",
		"description": "Описание",
		"delete": "Удалить",
		"confirm_delete_text": "Удалить проект ?",
		"edit": "Редактировать",
		"edit_project_info": "Редактировать проект",
		"objects": "Объекты",
		"PUD": "ПУД",
		"customer": "Заказчик",
		"contract": "Контракт",
		"files": "Вложения",
		"documents": "Документы",
		"methodology": "Методология",
		"technical_plan": "План выполнения технического надзора",
		"team_distribution": "Распределение команды",
		"social_standarts": "Экологические и соц. стандарты",
		"safety": "Техника безопасности и охрана труда",
		"projects_map": "Карта проектов",
		"employees": "Сотрудники",
		"add_employee": "Добавить сотрудника"
	},
	"object": {
		"table_title": "Объекты",
		"search_placeholder": "Поиск",
		"add_button": "Добавить",
		"add_dialog_title": "Добавить объект",
		"title": "Название",
		"date_start": "Дата создания",
		"date_end": "Окончание",
		"warranty_end_date": "Дата окончания гарантийного периода",
		"status": "Статус",
		"priority": "Приоритет",
		"supervision": "Технадзор",
		"progress": "Процент выполнения",
		"address": "Адрес",
		"responsible": "Ответственные",
		"select_type": "Тип",
		"select_priority": "Приоритет",
		"object": "Объект",
		"gpr": "ГПР",
		"prescriptions": "Предписания",
		"reports": "Отчеты",
		"tasks": "Задачи",
		"settings": "Настройки",
		"project_company": "Проектная компания",
		"organization": "Подрядная организация",
		"implementer": "Исполнитель",
		"delete": "Удалить",
		"confirm_delete_text": "Удалить объект ?",
		"edit_button": "Редактировать",
		"edit_dialog_title": "Редактировать объект",
		"employee": "Ответственные",
		"add_employee": "Добавить",
		"project": "Проект",
		"map_title": "Координаты объекта",
		"map_lat": "Широта",
		"map_lng": "Долгота",
		"map_radius": "Радиус",
		"settings_reports_previous_days": "Редактирование отчетов за прошлые дни",
		"settings_daily_reports": "Редактирование технадзором ежедневного отчета",
		"settings_copy_work_types_from_other_object": "Скопировать из другого объекта",
		"settings_work_types": "Виды работ",
		"settings_work_type": "Вид работ",
		"settings_work_category": "Категория",
		"settings_work_title": "Название",
		"settings_work_cost": "Стоимость",
		"settings_work_duration": "Длительность",
		"settings_work_start": "Начало",
		"settings_work_actions": "Действия",
		"settings_planned_workers": "Работники",
		"settings_planned_equipment": "Количество техники",
		"settings_row_kind": "Тип",
		"settings_add_row": "Добавить строку",
		"settings_edit_category": "Редактировать категорию",
		"settings_edit_work": "Редактировать вид работ",
		"settings_confirm_delete_row": "Подтверждаете удаление ?",
		"documents": "Документы",
		"avr": "АВР",
		"cumulative": "Накопительная",
		"checklists": "Чеклисты"
	},
	"avr": {
		"work_type": "Вид работ",
		"created_at": "Создан",
		"employee": "Создал",
		"cost": "Стоимость",
		"files": "Файлы",
		"add_button": "Добавить",
		"add_edit": "Редактировать",
		"add_title": "Добавить АВР",
		"edit_title": "Редактировать АВР",
		"confirm_delete": "Удалить АВР ?",
		"delete": "Удалить",
		"select_type": "Тип",
		"work_category": "Категория",
		"work_or_category": "Вид работ или категория"
	},
	"gpr": {
		"table_title": "Название",
		"table_duration": "Длит.",
		"table_real_duration": "Факт.",
		"table_start": "Начало",
		"table_end": "Конец",
		"table_completed": "%",
		"bar_completed": "Выполнение",
		"bar_outstending": "Отставание",
		"bar_left": "По плану",
		"bar_future": "Не начато",
		"equipments_quantity": "Количество техники",
		"workers_quantity": "Количество людей",
		"planned_equipments_quantity": "Запланированное количество техники",
		"planned_workers_quantity": "Запланированное количество людей",
		"empty_data": "Нет данных"
	},
	"cumulative": {
		"table_title": "Название",
		"table_cost": "План.",
		"table_avr_cost": "Факт.",
		"table_start": "Начало",
		"table_end": "Конец",
		"table_cost_percent": "%",
		"chart_actual": "Фактическая",
		"chart_planned": "Планируемая",
		"chart_remainder": "Остаток",
		"chart_excess": "Превышение",
		"total_actual": "Фактическая",
		"total_planned": "Планируемая"
	},
	"report": {
		"work_type": "Вид работ",
		"created_at": "Создан",
		"workers": "Рабочие",
		"equipment": "Техника",
		"status": "Статус",
		"search_by_id": "Поиск по Id",
		"add_button": "Добавить",
		"edit_button": "Редактировать",
		"edit_title": "Редактировать отчет",
		"add_title": "Добавить отчет",
		"completed": "Выполнено",
		"files": "Файлы",
		"actual_cost": "Фактическая стоимость",
		"notifications": "Отчеты"
	},
	"document": {
		"title": "Заголовок",
		"created_at": "Создан",
		"add_title": "Добавить документ",
		"add_button": "Добавить",
		"edit_title": "Редактировать отчет",
		"edit_button": "Редактировать",
		"date": "Дата",
		"date_start": "Дата от",
		"date_end": "Дата до",
		"search_by_title": "Поиск по названию",
		"content": "Содержание",
		"parameters": "Параметры",
		"print_button": "Распечатать"
	},
	"customer": {
		"coordinator": "Координатор проекта",
		"contact_person": "Контактное лицо",
		"address": "Адрес",
		"contacts": "Контактные данные",
		"edit": "Редактировать",
		"dialog_title": "Редактировать заказчика",
		"name": "Имя",
		"contact_person_post": "Должность"
	},
	"employee": {
		"employees": "Сотрудники",
		"name": "ФИО",
		"role": "Роль",
		"username": "Username",
		"phone": "Телефон",
		"email": "Email",
		"password": "Пароль",
		"branch": "Филиал",
		"login": "Логин",
		"search_placeholder": "Поиск по имени",
		"add_button": "Добавить",
		"add_title": "Добавить сотрудника",
		"table_title": "Ответственные",
		"select_role": "Роль",
		"select_object": "Объект",
		"object": "Объект",
		"projects": "Проекты",
		"objects": "Объекты",
		"timesheet": "Табель",
		"timesheet_id": "Id",
		"timesheet_title": "Значение",
		"timesheet_date": "Дата",
		"delete": "Удалить",
		"confirm_delete_text": "Удалить сотрудника ?",
		"edit_button": "Редактировать",
		"edit_title": "Редактировать сотрудника",
		"tasks": "Задачи",
		"all_tasks": "Все задачи",
		"post": "Должность",
		"main_specialist": "Ключевой специалист"
	},
	"task": {
		"id": "Id",
		"title": "Заголовок",
		"start": "Начало",
		"end": "Конец",
		"category": "Категория",
		"description": "Описание",
		"tasks": "Задачи",
		"add": "Добавить задачу",
		"update": "Редактировать задачу",
		"delete": "Удалить",
		"confirm_delete_text": "Удалить задачу ?",
		"date_start": "Дата начала",
		"date_complete": "Дата завершения",
		"type": "Тип",
		"status": "Статус",
		"level": "Уровень важности",
		"object": "Объект",
		"repeat": "Повтор",
		"result": "Результат выполнения",
		"created_at": "Создано",
		"employee": "Ответственный",
		"pt": "Пт",
		"ptg": "Птг",
		"npt": "Нпт",
		"nptg": "Нптг",
		"to": "То"
	},
	"calendar": {
		"check_all": "Выбрать все",
		"select_date": "Выбрать дату",
		"all_day": "Весь день",
		"add_button": "Добавить"
	},
	"prescription": {
		"prescription": "Предписание",
		"employee": "Технадзор",
		"title": "Название",
		"created_at": "Создан",
		"resolve_before": "Устранить до",
		"extended_until": "Продлено до",
		"resolved": "Фактическое устранение",
		"description": "Описание",
		"coords": "Координаты",
		"extend_reason": "Причина продления",
		"status": "Статус",
		"add_button": "Добавить",
		"edit_button": "Редактировать",
		"edit_title": "Редактировать предписание",
		"add_title": "Добавить предписание",
		"notifications": "Предписания"
	},
	"profile": {
		"profile": "Профиль",
		"logout": "Выйти",
		"edit_button": "Редактировать",
		"edit_title": "Редактировать профиль"
	},
	"wiki": {
		"category_title": "Заголовок",
		"category_add_button": "Добавить",
		"category_edit_button": "Редактировать",
		"category_edit_title": "Редактировать категорию",
		"category_add_title": "Добавить категорию",
		"article_title": "Заголовок",
		"article_add_button": "Добавить",
		"article_edit_button": "Редактировать",
		"article_add_title": "Добавить статью",
		"article_edit_title": "Редактировать статью",
		"article_content": "Содержание"
	},
	"settings": {
		"title": "Название",
		"key": "Ключ",
		"priorities": "Приоритет объекта",
		"statuses": "Статус объекта",
		"types": "Тип объекта",
		"value": "Значение",
		"color": "Цвет",
		"add_button": "Добавить",
		"edit_button": "Редактировать",
		"edit_title": "Редактировать настойку",
		"add_title": "Добавить настойку"
	},
	"branch": {
		"branches": "Филиалы",
		"details": "Подробнее",
		"projects": "Проекты",
		"add": "Добавить филиал",
		"title": "Название",
		"logo": "Логотип",
		"contacts": "Контакты",
		"coordinator_name": "Координатор",
		"coordinator_post": "Должность координатора",
		"edit_title": "Редактировать филиал",
		"add_title": "Добавить филиал"
	},
	"checklist": {
		"checklists": "Чеклисты",
		"templates": "Шаблоны",
		"title": "Заголовок",
		"created_at": "Создан",
		"updated_at": "Отредактирован",
		"day_of_week": "День недели",
		"employee": "Создал",
		"notes": "Заметки",
		"corrections": "Корректировки",
		"questions": "Вопросы",
		"add_button": "Добавить",
		"add_edit": "Редактировать",
		"add_title": "Добавить чеклист",
		"edit_title": "Редактировать чеклист",
		"value_ok": "Ок",
		"value_not_ok": "Не ок",
		"value_nz": "Нз",
		"add_template_button": "Добавить",
		"add_template_edit": "Редактировать",
		"add_template_title": "Добавить шаблон",
		"edit_template_title": "Редактировать шаблон",
		"repeat": "Повтор",
		"repeat_day": "День повтора",
		"organization": "Подрядная организация",
		"date": "Дата",
		"employees": "Сотрудники",
		"notifications": "Чеклисты",
		"add_description": "Чеклист создается из шаблона автоматически"
	},
	"day_of_week": {
		"mon": "Понедельник",
		"tue": "Вторник",
		"wed": "Среда",
		"thu": "Четверг",
		"fri": "Пятница",
		"set": "Суббота",
		"sun": "Воскресенье"
	},
	"repeats": {
		"onetime": "Единоразовая",
		"everyday": "Ежедневная",
		"everyweek": "Еженедельная",
		"everymonth": "Ежемесячная"
	},
	"timesheets": {
		"pt": "ПТ",
		"ptg": "ПТГ",
		"npt": "НПТ",
		"nptg": "НПТГ",
		"to": "ТО"
	},
	"notifications": {
		"remove_all": "Удалить все",
		"empty": "Пусто",
		"view_all": "Посмотреть все"
	}
}
