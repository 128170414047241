import { default as forgot_45passwordcZSvjJyvMcMeta } from "/home/igor/www/admin.qcontrol/pages/auth/forgot-password.vue?macro=true";
import { default as loginhVs77HoVEpMeta } from "/home/igor/www/admin.qcontrol/pages/auth/login.vue?macro=true";
import { default as reset_45passwordNyt4ik3jpmMeta } from "/home/igor/www/admin.qcontrol/pages/auth/reset-password.vue?macro=true";
import { default as two_45stepsncCsyqMgizMeta } from "/home/igor/www/admin.qcontrol/pages/auth/two-steps.vue?macro=true";
import { default as _91id_93P6IUcdoEPPMeta } from "/home/igor/www/admin.qcontrol/pages/employees/[id].vue?macro=true";
import { default as indexm60WOn6n0gMeta } from "/home/igor/www/admin.qcontrol/pages/employees/index.vue?macro=true";
import { default as indexUFfvY2hKuoMeta } from "/home/igor/www/admin.qcontrol/pages/index.vue?macro=true";
import { default as _91id_93J8XyrNu4DcMeta } from "/home/igor/www/admin.qcontrol/pages/objects/[id].vue?macro=true";
import { default as _91id_93ggWiksK6XbMeta } from "/home/igor/www/admin.qcontrol/pages/objects/documents/[id].vue?macro=true";
import { default as indexO5woGxYt9aMeta } from "/home/igor/www/admin.qcontrol/pages/objects/index.vue?macro=true";
import { default as _91id_937b9OJkH0jeMeta } from "/home/igor/www/admin.qcontrol/pages/prescriptions/[id].vue?macro=true";
import { default as indexXUHD9arVodMeta } from "/home/igor/www/admin.qcontrol/pages/profile/index.vue?macro=true";
import { default as _91id_93yG1TLXrMSRMeta } from "/home/igor/www/admin.qcontrol/pages/projects/[id].vue?macro=true";
import { default as _91id_93abF2haH3O4Meta } from "/home/igor/www/admin.qcontrol/pages/projects/documents/[id].vue?macro=true";
import { default as indexcngGnwzcatMeta } from "/home/igor/www/admin.qcontrol/pages/projects/index.vue?macro=true";
import { default as _91id_93yOg4T3sQKSMeta } from "/home/igor/www/admin.qcontrol/pages/reports/[id].vue?macro=true";
import { default as _91id_93sMqkV0r3ymMeta } from "/home/igor/www/admin.qcontrol/pages/responsibles/[id].vue?macro=true";
import { default as indexhQuJi6YtDiMeta } from "/home/igor/www/admin.qcontrol/pages/responsibles/index.vue?macro=true";
import { default as _91id_93P8XiUKxmdLMeta } from "/home/igor/www/admin.qcontrol/pages/settings/[id].vue?macro=true";
import { default as indexF1MRWOkcVSMeta } from "/home/igor/www/admin.qcontrol/pages/settings/index.vue?macro=true";
import { default as _91id_93hRMR0dWAH0Meta } from "/home/igor/www/admin.qcontrol/pages/wiki/[id].vue?macro=true";
import { default as _91id_93jtxcAiaqFjMeta } from "/home/igor/www/admin.qcontrol/pages/wiki/articles/[id].vue?macro=true";
import { default as indexiJQrKNdrfPMeta } from "/home/igor/www/admin.qcontrol/pages/wiki/index.vue?macro=true";
export default [
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45passwordcZSvjJyvMcMeta || {},
    component: () => import("/home/igor/www/admin.qcontrol/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginhVs77HoVEpMeta || {},
    component: () => import("/home/igor/www/admin.qcontrol/pages/auth/login.vue")
  },
  {
    name: "auth-reset-password",
    path: "/auth/reset-password",
    meta: reset_45passwordNyt4ik3jpmMeta || {},
    component: () => import("/home/igor/www/admin.qcontrol/pages/auth/reset-password.vue")
  },
  {
    name: "auth-two-steps",
    path: "/auth/two-steps",
    meta: two_45stepsncCsyqMgizMeta || {},
    component: () => import("/home/igor/www/admin.qcontrol/pages/auth/two-steps.vue")
  },
  {
    name: "employees-id",
    path: "/employees/:id()",
    component: () => import("/home/igor/www/admin.qcontrol/pages/employees/[id].vue")
  },
  {
    name: "employees",
    path: "/employees",
    component: () => import("/home/igor/www/admin.qcontrol/pages/employees/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/igor/www/admin.qcontrol/pages/index.vue")
  },
  {
    name: "objects-id",
    path: "/objects/:id()",
    component: () => import("/home/igor/www/admin.qcontrol/pages/objects/[id].vue")
  },
  {
    name: "objects-documents-id",
    path: "/objects/documents/:id()",
    component: () => import("/home/igor/www/admin.qcontrol/pages/objects/documents/[id].vue")
  },
  {
    name: "objects",
    path: "/objects",
    component: () => import("/home/igor/www/admin.qcontrol/pages/objects/index.vue")
  },
  {
    name: "prescriptions-id",
    path: "/prescriptions/:id()",
    component: () => import("/home/igor/www/admin.qcontrol/pages/prescriptions/[id].vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/home/igor/www/admin.qcontrol/pages/profile/index.vue")
  },
  {
    name: "projects-id",
    path: "/projects/:id()",
    component: () => import("/home/igor/www/admin.qcontrol/pages/projects/[id].vue")
  },
  {
    name: "projects-documents-id",
    path: "/projects/documents/:id()",
    component: () => import("/home/igor/www/admin.qcontrol/pages/projects/documents/[id].vue")
  },
  {
    name: "projects",
    path: "/projects",
    component: () => import("/home/igor/www/admin.qcontrol/pages/projects/index.vue")
  },
  {
    name: "reports-id",
    path: "/reports/:id()",
    component: () => import("/home/igor/www/admin.qcontrol/pages/reports/[id].vue")
  },
  {
    name: "responsibles-id",
    path: "/responsibles/:id()",
    component: () => import("/home/igor/www/admin.qcontrol/pages/responsibles/[id].vue")
  },
  {
    name: "responsibles",
    path: "/responsibles",
    component: () => import("/home/igor/www/admin.qcontrol/pages/responsibles/index.vue")
  },
  {
    name: "settings-id",
    path: "/settings/:id()",
    component: () => import("/home/igor/www/admin.qcontrol/pages/settings/[id].vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/home/igor/www/admin.qcontrol/pages/settings/index.vue")
  },
  {
    name: "wiki-id",
    path: "/wiki/:id()",
    component: () => import("/home/igor/www/admin.qcontrol/pages/wiki/[id].vue")
  },
  {
    name: "wiki-articles-id",
    path: "/wiki/articles/:id()",
    component: () => import("/home/igor/www/admin.qcontrol/pages/wiki/articles/[id].vue")
  },
  {
    name: "wiki",
    path: "/wiki",
    component: () => import("/home/igor/www/admin.qcontrol/pages/wiki/index.vue")
  }
]