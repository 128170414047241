import revive_payload_client_dJpsBMZNkr from "/home/igor/www/admin.qcontrol/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_7h5vzzqbmlomoravap6v7hwenq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_qxq9bWNnJa from "/home/igor/www/admin.qcontrol/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_7h5vzzqbmlomoravap6v7hwenq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Thy98EBsoE from "/home/igor/www/admin.qcontrol/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_7h5vzzqbmlomoravap6v7hwenq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_RAHPVAvrFU from "/home/igor/www/admin.qcontrol/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_7h5vzzqbmlomoravap6v7hwenq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_t22lk4zZKn from "/home/igor/www/admin.qcontrol/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_7h5vzzqbmlomoravap6v7hwenq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_SMTb8IjVdh from "/home/igor/www/admin.qcontrol/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_7h5vzzqbmlomoravap6v7hwenq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_T9Hz1gdUGb from "/home/igor/www/admin.qcontrol/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_7h5vzzqbmlomoravap6v7hwenq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_bStlsohkPI from "/home/igor/www/admin.qcontrol/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.2.2_vue@3.5.10_typescript@5.2.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/igor/www/admin.qcontrol/.nuxt/components.plugin.mjs";
import prefetch_client_nhuRwcGUFC from "/home/igor/www/admin.qcontrol/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_7h5vzzqbmlomoravap6v7hwenq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_3uXtxCBDTf from "/home/igor/www/admin.qcontrol/node_modules/.pnpm/nuxt-auth-sanctum@0.4.18_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/nuxt-auth-sanctum/dist/runtime/plugin.js";
import plugin_wy0B721ODc from "/home/igor/www/admin.qcontrol/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import index_NoOeBbAe38 from "/home/igor/www/admin.qcontrol/plugins/vuetify/index.js";
import index_HYtSsfhyFh from "/home/igor/www/admin.qcontrol/plugins/iconify/index.js";
import index_O6aXp1lQoC from "/home/igor/www/admin.qcontrol/plugins/i18n/index.js";
import ganttastic_client_En85f9BQ9v from "/home/igor/www/admin.qcontrol/plugins/ganttastic.client.js";
import layouts_OBoGUkHiof from "/home/igor/www/admin.qcontrol/plugins/layouts.js";
import settings_2MnfoqQ5ue from "/home/igor/www/admin.qcontrol/plugins/settings.js";
export default [
  revive_payload_client_dJpsBMZNkr,
  unhead_qxq9bWNnJa,
  router_Thy98EBsoE,
  payload_client_RAHPVAvrFU,
  navigation_repaint_client_t22lk4zZKn,
  check_outdated_build_client_SMTb8IjVdh,
  chunk_reload_client_T9Hz1gdUGb,
  plugin_vue3_bStlsohkPI,
  components_plugin_KR1HBZs4kY,
  prefetch_client_nhuRwcGUFC,
  plugin_3uXtxCBDTf,
  plugin_wy0B721ODc,
  index_NoOeBbAe38,
  index_HYtSsfhyFh,
  index_O6aXp1lQoC,
  ganttastic_client_En85f9BQ9v,
  layouts_OBoGUkHiof,
  settings_2MnfoqQ5ue
]