export default defineNuxtPlugin(async () => {
	const { data } = await apiIndex('settings', {
		defaultLimit: 1000,
	})

	return {
		provide: {
			settings: convertToNestedObject(data.value),
		},
	}
})

function convertToNestedObject(arr) {
	const result = {}

	arr.forEach(({ key, value }) => {
		if (key.startsWith('default.')) {
			key = key.replace('default.', '')
		}

		const keys = key.split('.')
		let current = result

		keys.forEach((k, index) => {
			if (index === keys.length - 1) {
				current[k] = value
			} else {
				if (!current[k]) {
					current[k] = {}
				}
				current = current[k]
			}
		})
	})

	return JSON.parse(JSON.stringify(result))
}
